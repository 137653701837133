<template>
    <div class="btn-group">
        <button class="btn btn-default dropdown-toggle" data-toggle="dropdown"><i class="las la-globe-europe"></i>
            <span v-if="$i18n.locale === 'it'" class="button-text">Italiano</span>
            <span v-else-if="$i18n.locale === 'en'" class="button-text">English</span>
        </button>
        <ul class="dropdown-menu dropdown-menu-right">
            <li><a href="" @click.prevent="selectLang('en')"><span class="button-text">English</span></a></li>
            <li><a href="" @click.prevent="selectLang('it')"><span class="button-text">Italiano</span></a></li>
        </ul>
    </div>
</template>

<script>

    import {LocaleHelper} from "../../common/localeHelper";
    import {StorageHelper} from "../../common/storageHelper";
    import {updateLanguageUser, updateLanguageUserConsumer} from "../../api";

    export default {
        name: "SelectLanguages",
        props: {
            defaultLanguage: {
                type: String,
                default: "it"
            }
        },
        mounted () {
            this.selectLang(this.defaultLanguage);
        },
        methods: {
            async selectLang(lang) {
                LocaleHelper.setLanguage(lang);
                StorageHelper.setDefaultLanguage(lang);

                let user = StorageHelper.getUser();
                let token = StorageHelper.getToken();
                let userConsumer = StorageHelper.getUserConsumer();
                let tokenConsumer = StorageHelper.getToken();

                if (user && token) {
                    try {
                        let userResponse = await updateLanguageUser(lang);
                        user.language = userResponse.language;
                        StorageHelper.setUser(user)
                    } catch (e) {
                        console.log(e)
                    }
                }

                if (userConsumer && tokenConsumer) {
                    try {
                        let userConsumerResponse = await updateLanguageUserConsumer(lang);
                        userConsumer.language = userConsumerResponse.language;
                        StorageHelper.setUserConsumer(userConsumer)
                    } catch (e) {
                        console.log(e)
                    }
                }
            },
        }
    };
</script>
