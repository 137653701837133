<style scoped>

    a, a:link, a:focus, a:hover {
        color: #8E0633 !important;
    }

    .btn-primary {
        background-color: #8E0633 !important;
        box-shadow: none;
    }

    body{
        padding-top: 0px;
    }
    .checkout-left-col {
        padding: 20px;
    }
    .checkout-right-col {
        background-color: #2d313f !important;
        padding: 60px;
        flex-direction: column;
        color: #fff;
    }
    .powered-by {
        padding: 100px 50px 50px 50px;
        text-align: center;
        line-height: 1;
        position: absolute;
        bottom: -164px;
        left: 0;
        right: 0;
    }
    @media(max-width:767px) {
        .checkout-row {
            margin: 0 -20px;
        }
    }
    @media(min-width:768px) {
        .checkout-row {
            display: flex;
            height: 100vh;
            flex-direction: row;
            margin: 0px;
            overflow: hidden;
        }
        .checkout-left-col {
            padding: 60px 60px 0 10%;
            flex-direction: column;
            overflow-y: scroll;
        }
        .checkout-left-col > .container-fluid {
            position: relative;
            min-height: calc(100% - 165px);
        }
    }
    @media (min-width:768px) and (max-width:991px) {
        .checkout-left-col {
            padding: 20px 20px 0 20px;
        }
        .checkout-right-col {
            padding: 20px;
        }
    }
    tr.active > td {
        background: none!important;
        font-weight: 900;
    }
</style>

<template>
    <div>
        <div class="container-fluid">
            <div class="row checkout-row">
                <div class="col-xs-12 col-sm-7 checkout-left-col">
                    <div class="container-fluid">
                        <div>
                            <h3 class="margin-bottom-20">{{$t('common.buy_ticket')}}</h3>
                            <ol class="breadcrumb checkout-progress">
                                <li :class="{'active': step===1}">{{$t('common.rate_information')}}</li>
                                <li :class="{'active': step===2}">{{$t('common.customer_information')}}</li>
                                <li :class="{'active': step===3}">{{$t('common.payment_information')}}</li>
                            </ol>
                            <hr>
                            <div v-show="step===1">
                                <h1 class="h3 margin-bottom-20">{{$t('common.rate_information')}}</h1>
                                <div class="panel panel-default margin-bottom-40" >
                                    <div class="panel-body">

                                        <BookingForm @reservation-data-changed="reservationDateChanged"
                                                     :show-customizing-date="true"
                                                     :show-client-section="false"
                                                     :other-free-rate-shown="false"
                                                     :error-selected-products="error.selectProducts"
                                                     :error-rates="error.rates"
                                                     :only-date="true"
                                                     :discount-visible="false"
                                        ></BookingForm>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6">
                                        {{$t('common.cancel')}}
                                    </div>
                                    <div class="col-xs-6 text-right">
                                        <button class="btn btn-primary btn-lg" @click.prevent.stop="goToStep2">{{$t('common.continue')}}</button>
                                    </div>
                                </div>
                            </div>
                            <div v-show="step===2">
                                <h1 class="h3 margin-bottom-20">{{$t('common.customer_information')}}</h1>
                                <div class="panel panel-default margin-bottom-40">
                                    <div class="panel-body">
                                        <div class="form-horizontal">
                                            <div class="form-group" :class="{'has-error': error.first_name}">
                                                <label for="first_name_client_modal" class="col-xs-12 col-md-4 control-label">{{ $t('common.first_name') }}*</label>
                                                <div class="col-xs-12 col-md-8">
                                                    <input class="form-control"
                                                           :disabled="checkoutBooking"
                                                           type="text"
                                                           placeholder="es: Mario"
                                                           name="first_name_client_modal"
                                                           id="first_name_client_modal"
                                                           v-model="userConsumer.first_name">
                                                    <span v-if="error.first_name" class="help-block">{{$t('error.field_required')}}</span>
                                                </div>
                                            </div>
                                            <div class="form-group" :class="{'has-error': error.last_name}">
                                                <label for="last_name_client_modal" class="col-xs-12 col-md-4 control-label">{{ $t('common.last_name') }}*</label>
                                                <div class="col-xs-12 col-md-8">
                                                    <input class="form-control"
                                                           :disabled="checkoutBooking"
                                                           type="text"
                                                           placeholder="es: Rossi"
                                                           id="last_name_client_modal"
                                                           name="last_name_client_modal"
                                                           v-model="userConsumer.last_name">
                                                    <span v-if="error.last_name" class="help-block">{{$t('error.field_required')}}</span>
                                                </div>
                                            </div>
                                            <div class="form-group" :class="{'has-error': error.phone_number}">
                                                <label for="phone_name_client_modal" class="col-xs-12 col-md-4 control-label">{{ $t('common.phone') }}*</label>
                                                <div class="col-xs-12 col-md-8">
                                                    <VuePhoneNumberInput
                                                            :disabled="checkoutBooking"
                                                            @update="updatePhoneNumber"
                                                            no-validator-state
                                                            :default-country-code="country_phone"
                                                            v-model="userConsumer.phone_number"
                                                            :translations="translations"
                                                            id="phone_name_client_modal"></VuePhoneNumberInput>
                                                    <span v-if="error.phone_number" class="help-block">{{$t('error.field_required')}}</span>
                                                </div>
                                            </div>
                                            <div class="form-group" :class="{'has-error': error.email}">
                                                <label for="email_name_client_modal" class="col-xs-12 col-md-4 control-label">{{ $t('common.email') }}*</label>
                                                <div class="col-xs-12 col-md-8">
                                                    <input class="form-control"
                                                           :disabled="checkoutBooking"
                                                           type="email"
                                                           placeholder="es: esempio@gmail.com"
                                                           id="email_name_client_modal"
                                                           name="email_name_client_modal"
                                                           v-model="userConsumer.email">
                                                    <span v-if="error.email" class="help-block">{{$t('error.field_required')}}</span>
                                                </div>
                                            </div>
                                            <div class="form-group" :class="{'has-error': error.country}" v-show="!checkoutBooking" >
                                                <label for="country_client_modal" class="col-xs-12 col-md-4 control-label">{{$t('common.country')}}*</label>
                                                <div class="col-xs-12 col-md-8">
                                                    <SelectCountry @country-changed="countryChanged" :default-country="userConsumer.country"></SelectCountry>
                                                    <span v-if="error.country" class="help-block">{{$t('error.field_required')}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6">
                                        <a href="" @click.prevent.stop="step=1">{{ $t('common.back') }}</a>
                                    </div>
                                    <div class="col-xs-6 text-right">
                                        <button class="btn btn-primary btn-lg" @click.prevent.stop="goToPaymentStep">{{$t('common.continue')}}</button>
                                    </div>
                                </div>
                            </div>
                            <div v-show="step===3">
                                <h1 class="h3 margin-bottom-20">{{$t('common.payment_information')}}</h1>
                                <div class="panel panel-default margin-bottom-40">
                                    <div class="panel-body">
                                        <div class="row margin-bottom-20">
                                            <div class="form-group">
                                                <div class="col-xs-12 col-md-12">
<!--                                                    <PriceTable :price="this.showcase.price" :currency="this.showcase.price_currency"></PriceTable>-->
                                                </div>
                                            </div>
                                        </div>
                                        <stripe-element-card
                                                id="client_credit_card"
                                                ref="elementRef"
                                                :pk="STRIPE_PUBLIC_KEY"
                                                @token="tokenCreated"
                                        />
                                        <div>
                                            <div class="form-group margin-top-20">
<!--                                                <ConversionNote :price="showcase.price" :currency="showcase.price_currency"></ConversionNote>-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6">
                                        <a href="" @click.prevent.stop="step=2">{{ $t('common.back') }}</a>
                                    </div>
                                    <div class="col-xs-6 text-right">
                                        <button class="btn btn-primary btn-lg" @click.stop.prevent="confirmPurchase">{{ $t('common.confirm') }}</button>
                                    </div>
                                </div>
                            </div>
                            <div v-show="step===4">
                                <h1 class="h3 margin-bottom-20">{{$t('common.completed_order_title')}}</h1>
                                <div class="panel panel-default margin-bottom-40">
                                    <div class="panel-body">
                                        <p>
                                            {{$t('common.completed_order_desc')}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="powered-by">
<!--                            <span class="text-muted">Powered by</span>-->
                        </div>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-5 checkout-right-col">
                    <div class="container-fluid">
                        <div class="text-right margin-bottom-40">
                            <SelectLanguages :default-language="defaultLanguage"></SelectLanguages>
                        </div>
                        <CashSummaryTable
                                :estimate-reservation="estimateReservation"
                        ></CashSummaryTable>
                    </div>
                </div>
            </div>
        </div>
        <SpinnerLoader v-show="loading"></SpinnerLoader>
    </div>
</template>

<script>
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css'
    import SpinnerLoader from "../components/common/SpinnerLoader";
    import {Utils} from "@/common/utils";
    import { StripeElementCard } from '@vue-stripe/vue-stripe';
    import BookingForm from "../components/Booking/BookingForm";
    import {bookingReservation, estimateReservation} from "../api";
    import CashSummaryTable from "../components/Booking/CashSummaryTable";
    import SelectCountry from "../components/common/SelectCountry";
    import {DEFAULT_LANGUAGE, STRIPE_PUBLIC_KEY} from "../common/constants";
    import localeMixin from "../common/localeMixin";
    import SelectLanguages from "../components/common/SelectLanguages";
    import baseMixin from "../common/baseMixin";
    import i18n from '../i18n'

    export default {
        title: "Ticket Booking",
        name: "BookingOnline",
        mixins: [localeMixin, baseMixin],
        components: { SpinnerLoader, VuePhoneNumberInput, StripeElementCard, BookingForm, CashSummaryTable, SelectCountry,
            SelectLanguages},
        data: () => {
            return {
                userConsumer: {
                },
                errorMessage: "",
                loading: false,
                step: 1,
                newClientPhoneNumberInternational: "",
                translations: Utils.getTranslationsPhoneField(),
                price_credit_card: "0.00",
                price_currency_credit_card: "",
                STRIPE_PUBLIC_KEY: STRIPE_PUBLIC_KEY,
                error: {
                    first_name: false,
                    last_name: false,
                    email: false,
                    country: false,
                    phone: false,
                    selectProducts: false,
                    rates: false
                },
                price: 0.00,
                defaultCurrency: Utils.getDefaultCurrency(),
                checkoutBooking: false,
                newReservation: {
                    reservation_items: [],
                    date: new Date().toISOString(),
                },
                estimateReservation: {
                    price: 0,
                    price_final: 0,
                    price_discount: 0,
                    reservation_item_grouped_by_product: {},
                    discount_people: false
                },
            }
        },
        mounted() {
            this.errorMessage = "";
            this.userConsumer.country = Utils.getDefaultCountry();
            this.userConsumer.timezone = Utils.getDefaultTimezone();
            this.userConsumer.default_currency = Utils.getDefaultCurrency();
        },
        computed: {
            defaultLanguage() {
                if (this.$route.params.lang) {
                    return this.$route.params.lang
                }
                return DEFAULT_LANGUAGE
            },
            country_phone() {
                return Utils.getDefaultCountry();
            }
        },

        methods: {
            confirmPurchase() {
                this.$refs.elementRef.submit();
            },
            async tokenCreated (token) {
                this.loading = true;
                let stripe = window.Stripe(this.STRIPE_PUBLIC_KEY);

                this.token = token;
                const result = await stripe.confirmCardPayment(this.client_secret, {
                    payment_method: {
                        card: {
                            token: token.id
                        },
                    }
                });


                if (result.error) {
                    if (result.error.type === "card_error") {
                        this.errorMessage = result.error.message
                    }
                } else {

                    if (result.paymentIntent.status === "succeeded") {
                        this.successBookingReservation()
                    }

                }

                this.loading = false;
            },
            successBookingReservation() {
                this.step=4;
            },
            validateClientForm() {
                this.error.first_name = false;
                this.error.last_name = false;
                this.error.email = false;
                this.error.phone_number = false;
                this.error.email = false;
                this.error.country = false;


                if (!this.userConsumer.first_name) {
                    this.error.first_name = true;
                }

                if (!this.userConsumer.country) {
                    this.error.country = true;
                }

                if (!this.userConsumer.last_name) {
                    this.error.last_name = true;
                }

                if (!this.userConsumer.email) {
                    this.error.email = true;
                }

                if (!this.userConsumer.country) {
                    this.error.country = true;
                }

                if (!this.userConsumer.phone_number) {
                    this.error.phone_number = true;
                }


                return !(this.error.first_name ||
                    this.error.last_name ||
                    this.error.email ||
                    this.error.country ||
                    this.error.phone_number)
            },
            validateFirstStepForm() {
                this.error.selectProducts = false;
                this.error.rates = false;

                if (this.newReservation.reservation_items.length === 0) {
                    this.error.selectProducts = true;
                } else {
                    this.newReservation.reservation_items.forEach(el => {
                       if (!Number.isInteger(el.quantity) || el.quantity <= 0) {
                           this.error.rates = true
                       }
                    });
                }


                return !(this.error.selectProducts ||  this.error.rates)

            },
            goToStep2() {
                if (this.validateFirstStepForm()) {
                    this.step=2;
                }
            },

            async goToPaymentStep() {
                if (this.validateClientForm()) {
                    if (!this.client_secret) {
                        this.loading = true;
                        try {
                            let response = await bookingReservation(
                                this.userConsumer.first_name,
                                this.userConsumer.last_name,
                                this.userConsumer.email,
                                this.newClientPhoneNumberInternational,
                                this.userConsumer.country,
                                this.newReservation.reservation_items,
                                this.newReservation.date,
                                this.$route.query.channel,
                                i18n.locale
                            );

                            if (response.reservation_state === this.Keys.RESERVATION_STATES.COMPLETED) {
                                this.step = 4
                            } else {
                                this.step = 3;
                                this.client_secret = response.payment_client_secret;
                                this.price_credit_card = response.price_amount;
                                this.price_currency_credit_card = response.price_currency;
                            }
                            this.loading = false;

                        } catch (e) {
                            this.loading = false;
                            console.log(e)
                        }
                    }

                }
                this.errorMessage = "";

            },
            updatePhoneNumber(result) {
                this.newClientPhoneNumberInternational = result.formatInternational;
            },
            getCurrencySymbolFromString(currency) {
                return Utils.getCurrencySymbolFromString(currency)
            },

            async reservationDateChanged (newReservationObj) {
                if (newReservationObj.reservation_items !== this.newReservation.reservation_items) {
                    try {
                        this.estimateReservation = await estimateReservation(newReservationObj.reservation_items);
                    } catch (e) {
                        console.log(e)
                    }
                }
                this.newReservation.reservation_items = newReservationObj.reservation_items;
                this.newReservation.date = newReservationObj.date;
            },

            countryChanged(newCountry) {
                this.userConsumer.country = newCountry;
            },
        }

    };
</script>
